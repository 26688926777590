interface ElemInputProps {
	children?: any
	hasError?: any
	errMsg?: any
	leftCount?: any
	label?: any
	required?: any
	value?: any
	labelOnTop?: boolean
}

const ElemInput: React.FC<ElemInputProps> = ({
	label,
	value,
	required,
	hasError,
	errMsg,
	leftCount,
	labelOnTop,
	children,
}) => {
	return (
		<label className="relative block">
			{children}

			<span
				className={
					(!!value || labelOnTop ? "-translate-y-3 text-[12px]" : " ") +
					"peer-focus:-translate-y-3 text-[20px] transition absolute left-0 top-0 text-black/40   px-4 h-[54px] leading-[54px] whitespace-nowrap pointer-events-none"
				}
			>
				{label} {required && "*"}
			</span>

			{leftCount && (
				<div className="absolute top-6 right-3 text-gray">
					{value.length}/{leftCount}
				</div>
			)}

			{hasError && <div className="formErrorMsg">{hasError.message || errMsg}</div>}
		</label>
	)
}

export default ElemInput
