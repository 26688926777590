import { injectIntoForm } from "./AZ"

const PrefillForm = () => {
	return (
		<>
			{process.env.NODE_ENV === "development" && (
				<>
					<button
						className="btn btn-blue"
						onClick={() => {
							setTimeout(() => {
								injectIntoForm("firstName", "Max")
							}, 300)
							setTimeout(() => {
								injectIntoForm("lastName", "Mustermann")
							}, 500)
							setTimeout(() => {
								injectIntoForm("zipCode", "33333")
							}, 700)
							setTimeout(() => {
								injectIntoForm("city", "Gütersloh")
							}, 900)
							setTimeout(() => {
								injectIntoForm("street", "An der Autobahn")
							}, 1100)
							setTimeout(() => {
								injectIntoForm("housenr", "310")
							}, 1300)
							setTimeout(() => {
								injectIntoForm("label", "testlabel")
							}, 1300)
						}}
					>
						+++ prefill form +++
					</button>
				</>
			)}
		</>
	)
}

export default PrefillForm
