import axios from "axios"

const domains = {
	"prod.fe.ferrero-nutella-fanpost-2024-frontend.campaign-loyalty.com": {
		apibase: "/",
	},
	"nutella-fanpost.de": {
		apibase: "/",
	},
	"www.nutella-fanpost.de": {
		apibase: "/",
	},
	localhost: {
		apibase: "/inte/",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "/inte/" }
}

export const checkOrderLimit = navigate => {
	const env = getEnvConfig()
	axios.get(env.apibase + "checkLimit.php?cv=" + Date.now()).then(res => {
		if (res.data.limitReached) {
			navigate("/promoover")
		}
	})
}

export const containsOnlyAllowedChars = name => {
	// eslint-disable-next-line

	const match = name.match(/[A-Za-zÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÔÕÖØÙÚÛÜÝÞßàáâäåçèéêëìíîïðñòóôõöøùúûüýþÿıOEoeŒœŠš&\\\-., +]/g)
	if (match && match.join("") === name) {
		return true
	}
	return false
}
