interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
	azMarkup?: any
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children, azMarkup }) => {
	return (
		<label className={"relative block " + (className || "")}>
			{azMarkup}
			<select
				className={
					"pt-5  w-full peer px-4 text-[20px] font-Noto text-black h-[54px] leading-[23px] placeholder-transparent rounded-[6px] bg-grayLight appearance-none bg-right bg-no-repeat outline-0 shadow-[0px_3px_3px_0px_rgba(0,0,0,0.15)_inset] " +
					(hasError ? "  js-formerror " : "")
				}
				{...props}
			>
				{children}
			</select>
			<span
				className={
					(!!props.value ? "-translate-y-3" : "") +
					" -translate-y-3 transition absolute left-0 top-0 font-Noto text-[20px] px-4 h-[54px] leading-[54px] pointer-events-none " +
					(hasError ? "  text-red " : " text-black/40 ")
				}
			>
				{label} {props.required && "*"}
			</span>
			<div>{hasError && <div className="formErrorMsg ">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemSelect
