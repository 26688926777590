const Reducer = (state, action) => {
	switch (action.type) {
		case "SET_UPDATE_STATE":
			return {
				...state,
				[action.key]: action.value,
			}
		case "SET_COMPO_ENTRY_MULTI":
			let tmpCompoMulti = { ...state.composition }

			return {
				...state,
				composition: {
					...tmpCompoMulti,
					...action.data,
				},
			}
		case "SET_COMPO_ENTRY":
			let tmpCompo = { ...state.composition }
			tmpCompo[action.key] = action.value

			return {
				...state,
				composition: tmpCompo,
			}

		case "SET_ORDER_ENTRY":
			let tmpOrder = { ...state.order }
			tmpOrder[action.key] = action.value

			return {
				...state,
				order: tmpOrder,
			}
		case "SET_ORDER_ENTRY_MULTI":
			let tmpOrderMulti = { ...state.order }

			return {
				...state,
				order: {
					...tmpOrderMulti,
					...action.data,
				},
			}
		case "SET_STATE_STRAPI":
			return {
				...state,
				strapi: {
					...state.strapi,
					[action.key]: action.value,
				},
			}
		default:
			return state
	}
}

export default Reducer
