import React, { createContext, useReducer } from "react"
import { BLACKLIST, BOOKS, COMPOSITION_OPTIONS, ITEMKEY } from "../config"
import Reducer from "./reducer"

const initialState = {}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
