interface AlertProps {
	children?: any
	className?: any
}

const Alert: React.FC<AlertProps> = ({ children, className }) => {
	return (
		<section
			className={
				" p-5 rounded-[9px] border-2 border-white border-solid text-white bg-red grid grid-cols-1 md:grid-cols-[50px_1fr] gap-6 items-center font-bold font-Noto " +
				className
			}
		>
			<svg
				className="hidden sm:inline-block mx-auto"
				width={50}
				height={44}
				viewBox="0 0 50 44"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M25.0001 0.600098C23.4478 0.600098 22.118 1.54598 21.5548 2.89333L1.29486 37.835V37.8386C0.929216 38.4276 0.734802 39.1068 0.733398 39.8001C0.733398 40.7902 1.12673 41.7398 1.82687 42.44C2.527 43.1401 3.47659 43.5334 4.46673 43.5334C4.55433 43.5329 4.64189 43.5292 4.72923 43.5225L4.73652 43.5334H25.0001H45.2636L45.2709 43.5188C45.3582 43.5268 45.4458 43.5317 45.5334 43.5334C46.5235 43.5334 47.4731 43.1401 48.1733 42.44C48.8734 41.7398 49.2667 40.7902 49.2667 39.8001C49.266 39.1056 49.0716 38.4251 48.7053 37.835L48.6761 37.784L48.6725 37.7803L28.4454 2.89333C27.8821 1.54598 26.5523 0.600098 25.0001 0.600098ZM22.736 16.2043H27.2641L26.8886 28.2866H23.1115L22.736 16.2043ZM25.0074 31.9798C26.5418 31.9798 27.461 32.8053 27.461 34.2147C27.461 35.5979 26.5418 36.4204 25.0074 36.4204C23.4618 36.4204 22.5355 35.5979 22.5355 34.2147C22.5355 32.8053 23.4599 31.9798 25.0074 31.9798Z"
					fill="white"
				/>
			</svg>
			{children}
		</section>
	)
}

export default Alert
