import clsx from "clsx"
import ElemInput from "../elements/Input"
import ElemCheckbox from "../elements/Checkbox"
import { Link } from "react-router-dom"
import { azLoadCities, isAzActivated } from "./AZ"
import ElemSelect from "../elements/Select"
import { formRegEx } from "../config"

export function FieldSalutation({ register, errors }) {
	return (
		<ElemSelect
			className={"max-w-[190px]"}
			label={"Anrede"}
			props={{
				...register("salutation", { required: true }),
				name: "salutation",
				type: "select",
				// onChange: e => formChanged(e, item.name),
				// defaultValue: address[item.name!],
				required: true,
			}}
			hasError={errors.salutation}
			errMsg={"Bitte salutation eingeben."}
		>
			<option value="4">Keine Angabe</option>
			<option value="1">Herr</option>
			<option value="2">Frau</option>
			<option value="3">neutrale Anrede</option>
		</ElemSelect>
	)
}

export function FieldFirstname({ register, errors, watch }) {
	return (
		<ElemInput
			label="Vorname"
			hasError={errors.firstName}
			errMsg={"Bitte Vornamen eingeben."}
			required={true}
			value={watch("firstName", false)}
		>
			<input
				{...register("firstName", { required: true, minLength: 1, pattern: formRegEx.alpha })}
				type="text"
				className={clsx("peer inputElem", errors.firstName ? "inputElemErr js-formerror" : " border-0")}
			/>
		</ElemInput>
	)
}
export function FieldLabelName({ register, errors, watch }) {
	return (
		<ElemInput
			label="Name Etikett"
			hasError={errors.label}
			errMsg={"Dieses Feld muss ausgefüllt sein. Max. 12 Zeichen."}
			required={true}
			value={watch("label", false)}
		>
			<input
				{...register("label", { required: true, minLength: 1, maxLength: 12, pattern: formRegEx.alpha })}
				type="text"
				maxLength={12}
				className={clsx("peer inputElem", errors.label ? "inputElemErr js-formerror" : " border-0")}
			/>
		</ElemInput>
	)
}

export function FieldLastname({ register, errors, watch }) {
	return (
		<ElemInput
			label="Nachname"
			hasError={errors.lastName}
			errMsg={"Bitte Nachnamen eingeben."}
			required={true}
			value={watch("lastName", false)}
		>
			<input
				{...register("lastName", {
					minLength: 1,
					pattern: formRegEx.alpha,
					required: true,
				})}
				type="text"
				className={clsx("peer inputElem", errors.lastName ? "inputElemErr js-formerror" : " border-0")}
			/>
		</ElemInput>
	)
}

export function FieldZipCode({ register, errors, watch, setValue, setAzZIPS, azZIPS, setAzCITIES }) {
	return (
		<ElemInput
			label="PLZ"
			hasError={errors.zipCode}
			errMsg={"Das Feld muss 5 Zahlen enthalten."}
			required={true}
			value={watch("zipCode", false)}
		>
			<input
				{...register("zipCode", { required: true, minLength: 5, maxLength: 5 })}
				type="text"
				className={clsx("peer inputElem", errors.zipCode ? "inputElemErr js-formerror" : " border-0")}
			/>
			{isAzActivated && azZIPS && (
				<div className="max-h-[99px] mt-1 overflow-y-auto">
					Vorschläge:{" "}
					<ul>
						{azZIPS.map(item => (
							<li
								onClick={() => {
									setAzZIPS(null)
									setValue("zipCode", item)
									azLoadCities(item, res => {
										setAzCITIES(res)
									})
								}}
							>
								{item}
							</li>
						))}
					</ul>
				</div>
			)}
		</ElemInput>
	)
}

export function FieldCity({ register, errors, watch, azCITIES, setAzCITIES, setValue }) {
	return (
		<ElemInput
			label="Stadt"
			hasError={errors.city}
			errMsg={"Bitte Ort eingeben."}
			required={true}
			value={watch("city", false)}
		>
			<input
				{...register("city", {
					minLength: 1,
					pattern: formRegEx.alpha,
					required: true,
				})}
				type="text"
				className={clsx("peer inputElem", errors.city ? "inputElemErr js-formerror" : " border-0")}
			/>
			{isAzActivated && azCITIES && (
				<div className="max-h-[99px] mt-1 overflow-y-auto">
					Vorschläge:{" "}
					<ul>
						{azCITIES.map(item => (
							<li
								onClick={() => {
									setAzCITIES(null)
									setValue("city", item)
								}}
							>
								{item}
							</li>
						))}
					</ul>
				</div>
			)}
		</ElemInput>
	)
}

export function FieldStreet({ register, errors, watch, setValue, azSTREETS, setAzSTREETS }) {
	return (
		<ElemInput
			label="Straße"
			hasError={errors.street}
			errMsg={"Bitte Straße eingeben."}
			required={true}
			value={watch("street", false)}
		>
			<input
				{...register("street", { minLength: 1, required: true })}
				type="text"
				className={clsx("peer inputElem", errors.street ? "inputElemErr js-formerror" : " border-0")}
			/>
			{isAzActivated && azSTREETS && (
				<div className="max-h-[99px] mt-1 overflow-y-auto">
					Vorschläge:{" "}
					<ul>
						{azSTREETS.map(item => (
							<li
								onClick={() => {
									setAzSTREETS(null)
									setValue("street", item)
								}}
							>
								{item}
							</li>
						))}
					</ul>
				</div>
			)}
		</ElemInput>
	)
}

export function FieldHouseNr({ register, errors, watch }) {
	return (
		<ElemInput
			label="Haus-Nr."
			hasError={errors.housenr}
			errMsg={"Bitte Haus-Nr. eingeben."}
			required={true}
			value={watch("housenr", false)}
		>
			<input
				{...register("housenr", {
					minLength: 1,
					maxLength: 10,
					required: true,
				})}
				type="text"
				className={clsx("peer inputElem", errors.housenr ? "inputElemErr js-formerror" : " border-0")}
			/>
		</ElemInput>
	)
}

export function FieldAddressAdd({ register, errors, watch }) {
	return (
		<ElemInput
			label="Adresszusatz"
			hasError={errors.addressAdd}
			errMsg={"Bitte Adresszusatz eingeben."}
			value={watch("addressAdd", false)}
		>
			<input
				{...register("addressAdd", {})}
				type="text"
				className={clsx("peer inputElem", errors.addressAdd ? "inputElemErr js-formerror" : " border-0")}
			/>
		</ElemInput>
	)
}

export function FieldEmail({ register, errors, watch, isRequired = false }) {
	return (
		<ElemInput
			label={"E-Mail" + (isRequired ? " *" : "")}
			hasError={errors.email}
			errMsg={"Das Feld darf nicht leer sein oder das Format ist ungültig."}
			// required={true}
			value={watch("email", false)}
		>
			<input
				{...register("email", {
					minLength: 1,
					pattern: formRegEx.email,
					required: isRequired,
				})}
				type="email"
				className={clsx("peer inputElem", errors.email ? "inputElemErr js-formerror" : " border-0")}
			/>
		</ElemInput>
	)
}

export function FieldTerms({ register, errors }) {
	return (
		<ElemCheckbox
			className="mb-8"
			hasError={errors.terms}
			errMsg={"Terms bestätigen"}
			label={
				<div className="p-link">
					Durch Klicken auf die Schaltfläche „Jetzt gratis bestellen“ werden die{" "}
					<Link target="_blank" rel="noopener noreferrer" to="/teilnahmebedingungen">
						Teilnahmebedingungen
					</Link>{" "}
					der Ferrero Deutschland GmbH akzeptiert. Die Datenschutzerklärung ist{" "}
					<Link target="_blank" rel="noopener noreferrer" to="/datenschutz">
						hier
					</Link>{" "}
					zu finden. *
				</div>
			}
		>
			<input {...register("terms", { required: true })} type="checkbox" className="w-1 h-1 hidden absolute peer" />
		</ElemCheckbox>
	)
}

export function FieldBirthday({ register, errors, watch }) {
	return (
		<ElemInput
			label="Geburtsdatum *"
			hasError={errors.dateofbirth}
			errMsg={"Die Teilnahme ist erst ab 18 Jahren möglich. Das Datumsformat muss TT.MM.YYYY entsprechen."}
			value={watch("dateofbirth", false)}
			labelOnTop={true}
		>
			<input
				{...register("dateofbirth", {
					required: true,
					minLength: 1,
					maxLength: 10,
				})}
				type="date"
				className={clsx("peer inputElem", errors.dateofbirth ? "inputElemErr js-formerror" : " border-0")}
			/>
		</ElemInput>
	)
}
