"use client"
import axios from "axios"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { azLoadStreets, azLoadZips, getSessionID, isAzActivated } from "../Form/AZ"
import {
	FieldAddressAdd,
	FieldCity,
	FieldFirstname,
	FieldHouseNr,
	FieldLabelName,
	FieldLastname,
	FieldSalutation,
	FieldStreet,
	FieldZipCode,
} from "../Form/FerreroFormFields"
import PrefillForm from "../Form/PrefillForm"
import Alert from "../elements/Alert"
import { getEnvConfig } from "../env"
import Spinner from "../elements/Spinner"

const AddressForm = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [showErrorDialog, setShowErrorDialog] = useState<any>(null)

	const [thankyouPageFormData, setThankyouPageFormData] = useState<any>(null)

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm()

	function standardizeStreetName(address: string): string {
		// Regular expression to match "straße", "strasse", and "str" at the end of the string (case insensitive)
		const streetNamePattern = /straße|strasse|str\b/gi

		// Function to replace matches with the correct format
		const replaceFunction = (match: string, offset: number, string: string): string => {
			// Determine if the matched pattern is at the end of the address
			const isAtEnd = offset + match.length === string.length

			// Check if the matched pattern is part of another word
			const isPartOfWord = offset > 0 && !/\s/.test(string.charAt(offset - 1))

			if (isAtEnd) {
				return " Str."
			} else if (isPartOfWord) {
				return "str."
			} else {
				return " Str."
			}
		}

		// Apply the replacement only if the address contains patterns to be replaced
		if (streetNamePattern.test(address)) {
			return address.replace(streetNamePattern, replaceFunction)
		}

		return address
	}

	// ---------------------------- AZ

	const [azZIPS, setAzZIPS] = useState<any>(null)
	const [azCITIES, setAzCITIES] = useState<any>(null)
	const [azSTREETS, setAzSTREETS] = useState<any>(null)

	useEffect(() => {
		if (isAzActivated) {
			const subscription = watch((value, { name, type }) => {
				// handle zip
				if (name === "zipCode" && type === "change") {
					const { zipCode } = value
					if (zipCode.length > 2) {
						azLoadZips(zipCode, res => {
							setAzZIPS(res)
						})
					} else {
						getSessionID()
					}
				}
				// handle street
				if (name === "street" && type === "change") {
					const { street, zipCode } = value
					if (street.length > 2) {
						azLoadStreets(zipCode, street, res => {
							setAzSTREETS(res)
						})
					}
				}
			})
			return () => subscription.unsubscribe()
		}
	}, [watch])

	// ------------------ form handling

	function handleFormSubmit(data) {
		setShowErrorDialog(null)
		const env = getEnvConfig()

		setIsLoading(true)

		data.street = standardizeStreetName(data.street)

		axios
			.post(env.apibase + "order.php?cv=" + Date.now(), data)
			.then(res => {
				if (res.status === 200) {
					if (res.data.externalIdentifier) {
						setThankyouPageFormData(true)
					} else {
						setShowErrorDialog("Response fehlgeschlagen. externalIdentifier missing.")
					}
				} else {
					setShowErrorDialog("Response fehlgeschlagen. Status: " + res.status)
				}
				setIsLoading(false)
			})
			.catch(error => {
				setShowErrorDialog("Datenübertragung fehlgeschlagen.")
				setIsLoading(false)
			})
	}

	return (
		<div className="bg-[url('/src/images/bg.png')] bg-repeat">
			{thankyouPageFormData ? (
				<div className=" max-w-[530px] mx-auto px-4 py-20">
					<h2 className="h2">Vielen Dank für Deine Bestellung.</h2>
				</div>
			) : (
				<div className=" max-w-[530px] mx-auto px-4 py-20">
					<h2 className="h2">Adresse eingeben und Deine nutella Fanpost bestellen</h2>
					<PrefillForm />
					<form onSubmit={handleSubmit(handleFormSubmit)}>
						<div className="mb-3 grid grid-cols-1 gap-6">
							<FieldSalutation register={register} errors={errors} />
							<FieldFirstname errors={errors} register={register} watch={watch} />
							<FieldLastname errors={errors} register={register} watch={watch} />
							<FieldZipCode
								errors={errors}
								register={register}
								watch={watch}
								azZIPS={azZIPS}
								setAzCITIES={setAzCITIES}
								setAzZIPS={setAzZIPS}
								setValue={setValue}
							/>
							<FieldCity
								errors={errors}
								register={register}
								watch={watch}
								azCITIES={azCITIES}
								setAzCITIES={setAzCITIES}
								setValue={setValue}
							/>
							<FieldStreet
								errors={errors}
								register={register}
								watch={watch}
								azSTREETS={azSTREETS}
								setAzSTREETS={setAzSTREETS}
								setValue={setValue}
							/>
							<FieldHouseNr errors={errors} register={register} watch={watch} />
							<FieldAddressAdd errors={errors} register={register} watch={watch} />

							<FieldLabelName errors={errors} register={register} watch={watch} />

							{/* <FieldTerms errors={errors} register={register} /> */}
						</div>
						{isLoading && (
							<div className="text-center mt-8">
								<Spinner />
							</div>
						)}

						<div className="text-center my-8">
							<button className="btn" type="submit">
								absenden
							</button>
						</div>
					</form>

					{showErrorDialog && <Alert>{showErrorDialog}</Alert>}
				</div>
			)}
		</div>
	)
}

export default AddressForm
