import axios from "axios"
import { useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { getEnvConfig } from "./env"
import Error from "./nav/Error"
import Home from "./nav/Home"
import Store from "./store/store"

const App = () => {
	const env = getEnvConfig()

	const keepSessionAlife = () => {
		axios.post(env.apibase + "keepalive.php?cv=" + Date.now())
		setTimeout(keepSessionAlife, 30000)
	}

	useEffect(() => {
		window.location.host !== "localhost:3000" && keepSessionAlife()

		// eslint-disable-next-line
	}, [])

	return (
		<BrowserRouter>
			<Store>
				<main className="overflow-x-hidden">
					<Routes>
						<Route path="/" element={<Navigate to="/vkl-edition" />} />
						<Route path="/vkl-edition" element={<Home />} />

						<Route path="/:err" element={<Error />} />
					</Routes>
				</main>
			</Store>
		</BrowserRouter>
	)
}

export default App
