const Spinner = () => {
	return (
		<svg className="animate-spinner inline-block" viewBox="0 0 50 50" width="50" height="50">
			<circle
				className="animate-spinnerDash"
				cx={25}
				cy={25}
				r={20}
				fill="none"
				strokeLinecap="round"
				stroke="#6eae60"
				strokeWidth={5}
			/>
		</svg>
	)
}

export default Spinner
