export enum ErrorMessage {
	NONE,
	EMPTY,
	UNKNOWN,
	ALLOWED,
	BLOCKED,
	NOTALLOWEDCHAR,
}
export enum TextBookKeys {
	HOMEPAGE = "homepage",
	FAQ = "faq",
	TERMS = "term",
	CONTACT = "contact",
	EXTENDED_PRIVACY = "extendeprivacy",
}

export enum GENDER {
	M = "gender_m",
	W = "gender_w",
}
export enum BOOKS {
	BOOK3 = "training",
	BOOK1 = "abenteuer",
	BOOK2 = "ball",
	BOOK4 = "panne",
}
export const BOOKDESCRIPTION = {
	training: {
		headline: "Profitraining:",
		copy: "Was für ein Pech. Das letzte Spiel war eine Katastrophe, weil die Hauptfigur einen blöden Fehler gemacht hat. Wie soll das nur in Zukunft besser werden? Doch beim nächsten Training gibt es eine große Überraschung: Drei Spieler der deutschen Männer-Nationalmannschaft machen diesmal mit. Und tatsächlich läuft heute vieles besser. Das Beste von allem ist aber Timos Geheimtipp. Ob der auch morgen bei ihrem wichtigen Spiel helfen kann?",
	},
	abenteuer: {
		headline: "Fußball-Abenteuer:",
		copy: "Einlaufkinder bei einem Spiel der deutschen Männer-Nationalmannschaft! Das Fußballteam kann kaum glauben, was der Trainer erzählt. Und es kommt sogar noch besser. Denn eins der Kinder darf den Ball aufs Spielfeld tragen. Wer es sein darf, lost das Team heute aus. Alle warten und halten vor Spannung die Luft an. Es wird: die Hauptfigur. Und damit geht das große Fußballabenteuer erst so richtig los …",
	},
	ball: {
		headline: "Verschwundener Ball:",
		copy: "Die Hauptfigur hat einen ganz besonderen Schatz: einen Fußball mit den Unterschriften der deutschen Männer-Nationalmannschaft. Der Ball thront über dem Bett. Aber – auwei! Plötzlich ist der Ball verschwunden. Was ist passiert? Nun geht die abenteuerliche Suche nach dem Ball los, mit Lieblingshund Evi als Unterstützung. Denn Evi liebt Bälle und ist eine super Spürnase.",
	},
	panne: {
		headline: "Tollste Panne:",
		copy: "Die Hauptfigur und Tante Gerda stehen auf dem Hofplatz, als das Unglaubliche passiert. Auf einmal stehen drei Spieler der deutschen Männer-Nationalmannschaft im Hof und bitten um Hilfe. Ihr Mannschaftsbus ist im Graben gelandet. Was für ein Glück, dass das gerade hier passiert ist! Denn Tante Gerda und ihr Traktor sind die perfekten Retter für solche Notfälle. Und das ist erst der Anfang der Geschichte …",
	},
}

export enum ITEMKEY {
	KLEIDUNG = "kleidung",
	HAIRSTYLE = "hairstyle",
	FARBE = "farbe",
	HAARE = "haare",
	HAUT = "haut",
	BRILLE = "brille",
	SOMMERSSPOSSEN = "sommersprossen",
}
export enum BLACKLIST {
	NONE = "NONE",
	UNKNOWN = "UNKNOWN",
	BLOCKED = "BLOCKED",
	ALLOWED = "ALLOWED",
}

export const COMPOSITION_OPTIONS = {
	[ITEMKEY.HAUT]: {
		components: [
			{
				headline: "Hautton",
				key: ITEMKEY.HAUT,
				options: ["haut_hell", "haut_mokka", "haut_schwarz"],
				order: 2,
			},
		],
	},

	[ITEMKEY.SOMMERSSPOSSEN]: {
		components: [
			{
				headline: "Sommersprossen",
				key: ITEMKEY.SOMMERSSPOSSEN,
				options: [false, true],
				order: 4,
			},
		],
	},
	[ITEMKEY.BRILLE]: {
		components: [
			{
				headline: "Brille",
				key: ITEMKEY.BRILLE,
				options: [false, true],
				order: 5,
			},
		],
	},
	[ITEMKEY.HAIRSTYLE]: {
		combinedKeyMapping: "[haare]-[farbe]",
		components: [
			{
				headline: "Haarfarbe",
				key: ITEMKEY.FARBE,
				options: ["blond", "rot", "braun", "schwarz"],
				order: 1,
			},
			{
				headline: "Frisur",
				key: ITEMKEY.HAARE, // state id for single items
				options: ["haare_kurz", "haare_gestyltkurz", "haare_mittel", "haare_zopf", "haare_lang"],
				order: 0,
			},
		],
	},
}

export function gtmEvt(evt) {
	try {
		// eslint-disable-next-line
		let dataLayer = window["dataLayer"] || []
		dataLayer.push({
			event: evt,
		})
	} catch (e) {}
}

export const AZReferer = "restful.ferrero.az"
export const AZUsername = "ferr81g5-arva-5572-t3cv-75h5ap061k2u"
export const AZPassword = "j739pde2-k64k-7939-oo80-38je2gh5pt3a"

export const formRegEx = {
	// eslint-disable-next-line
	alpha: new RegExp(
		"^[A-Za-zäöüßãâåàáäæçéèêëìíîïłñôõóòøúûÿıÄÖÜÃÂÅÀÁÄÆÇÉÈÊËÌÍÎÏŁÑÔÕÓÒØÚÛŸI0-9]+[A-Za-zäöüßãâåàáäæçéèêëìíîïłñôõóòøúûÿıÄÖÜÃÂÅÀÁÄÆÇÉÈÊËÌÍÎÏŁÑÔÕÓÒØÚÛŸI0-9s. -]*.?"
	),
	// eslint-disable-next-lin
	email: new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
	numerical: new RegExp("^[0-9]*$"),
	// eslint-disable-next-line
	birthdate: new RegExp("^([0-2][0-9]|(3)[0-1])(.)(((0)[0-9])|((1)[0-2]))(.)d{4}$"),
}
