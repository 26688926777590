import React, { useEffect } from "react"

const Error: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<section className="bg-white   ">
			<div className="container max-w-[1200px] mx-auto px-8  py-32  ">
				<h1 className="h1 text-left pt-24">Hier ist etwas schief gelaufen...</h1>
				<a href={process.env.PUBLIC_URL} className="inline-block btn btn-blue mt-8">
					Zurück zur Aktion
				</a>
			</div>
		</section>
	)
}
export default Error
