import React from "react"
import TagManager from "react-gtm-module"
import AddressForm from "./AddressForm"

const Home: React.FC = () => {
	// GTM
	const tagManagerArgs = {
		dataLayer: {
			page: "startseite",
		},
		dataLayerName: "dataLayer",
	}
	TagManager.dataLayer(tagManagerArgs)
	// SEO Meta Tags

	function scrollToCodeArea() {
		const doc: any = document
		const { top } = doc.querySelector(".js-codeeingeben").getBoundingClientRect()

		window.scrollTo({
			top: top + window.scrollY,
			left: 0,
			behavior: "smooth",
		})

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({
				event: "raffle",
				rafflestatus: "code",
			})
		} catch (e) {}
	}

	return (
		<>
			<picture>
				<source media="(min-width: 640px)" srcSet={"/images/desktop.jpg"} />
				<img src={"/images/mobile.jpg"} className="w-full relative z-0 headerPadding" alt="" />
			</picture>

			<AddressForm />
		</>
	)
}
export default Home
